import { entity } from "simpler-state";
import {
  addProductType,
  IProduct,
  ProductsStateType,
} from "../types/products.types";
import { languageCodes } from "./platform.entity";
import { IBrand, productType } from "../types/global.types";

export const langObj: { [key: string]: string } = {};
languageCodes.forEach((key) => {
  langObj[key] = "";
});

const defaultState: ProductsStateType = {
  loading: false,
  searching: false,
  layout: "grid",
  selectedProduct: null,
  newProduct: {
    name: { ...langObj },
    caption: { ...langObj },
    description: { ...langObj },
    disclaimer: { ...langObj },
    categories: [],
    marketplace: "",
    internalCategory: "",
    redemptionValidityType: "period",
    redemptionValidityPeriodType: "weeks",
    redemptionValidityValue: 1,
    partnerId: "",
    canBeRedeemedAsRewards: false,
    isCountedTowardsReward: false,
    canBeSent: "immediately",
    canBeSentPeriodType: "weeks",
    redeemType: "",
    productType: "regular-product",
    isRated18: false,
    status: "Active",
    tax: 0,
  },
  selectedProducts: [],
  selectedBrandProduct: null,
  selectedBrandInfo: null,
  visitedEditProduct: false,
  selectedProductType: "",
};

export const productsEntity = entity<ProductsStateType>(defaultState);

export const setProducts = (products: IProduct[]) =>
  productsEntity.set((currentState) => ({
    ...currentState,
    list: products,
  }));

export const toggleProductsLayout = () =>
  productsEntity.set((currentState) => ({
    ...currentState,
    layout: currentState.layout === "table" ? "grid" : "table",
  }));

export const setProductsLayout = (layout: ProductsStateType["layout"]) =>
  productsEntity.set((currentState) => ({
    ...currentState,
    layout: layout,
  }));

export const toggleProductLoadingState = () =>
  productsEntity.set((currentState) => ({
    ...currentState,
    loading: !currentState.loading,
  }));

export const toggleProductSearchingState = () =>
  productsEntity.set((currentState) => ({
    ...currentState,
    searching: !currentState.searching,
  }));

export const productHeadings = [
  { name: "Product name", key: "name" },
  { name: "Supplier", key: "supplier" },
  { name: "Marketplace", key: "marketplace" },
  { name: "Price", key: "price" },
  { name: "Quantity limit type", key: "quantityLimitType" },
  // { name: "Redemption period", key: "redemptionPeriod" },
  { name: "Valid thru", key: "validThru" },
  { name: "Added on", key: "createdAt" },
  { name: "Status", key: "status" },
];

export const discountHeading = [
  { name: "Discount code", key: "code" },
  { name: "Discount value", key: "value" },
  { name: "Discount type", key: "discountType" },
  { name: "Orders", key: "order" },
  { name: "Status", key: "isActive" },
];

export const addProduct = (data: Partial<addProductType>) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    newProduct: { ...prevState.newProduct, ...data },
  }));
};

export const clearProduct = () => {
  productsEntity.set((prevState) => ({
    ...prevState,
    newProduct: defaultState.newProduct,
  }));
};

export const setSelectedProduct = (product: IProduct) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    selectedProduct: product,
  }));
};

export const setSelectedProductsForAction = (product: string) => {
  const { selectedProducts } = productsEntity.get();

  if (selectedProducts.includes(product)) {
    productsEntity.set((prevState) => ({
      ...prevState,
      selectedProducts: selectedProducts.filter((p) => p !== product),
    }));
  } else {
    productsEntity.set((prevState) => ({
      ...prevState,
      selectedProducts: prevState.selectedProducts.concat(product),
    }));
  }
};

export const resetSelectedProductsForAction = () => {
  productsEntity.set((prevState) => ({
    ...prevState,
    selectedProducts: [],
  }));
};

export const setSelectedBrandProduct = (product: IProduct | null) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    selectedBrandProduct: product,
  }));
};

export const setSelectedBrandInfo = (brand: IBrand | null) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    selectedBrandInfo: brand,
  }));
};

export const setVisitedEditProduct = (value: boolean) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    visitedEditProduct: value,
  }));
};

export const setSelectedProductType = (value: productType) => {
  productsEntity.set((prevState) => ({
    ...prevState,
    selectedProductType: value,
  }));
};
